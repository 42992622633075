import axios from 'axios'
import qs from 'qs'
import axiosRetry from 'axios-retry'
import { isCancellable } from '@/api/interceptor.js'

export const instance = axios.create({
  baseURL: '/',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { skipNulls: true, indices: false }) // to get: param=value1&param=value2
  }
})

export const oauthInstance = axios.create({
  baseURL: '/',
  timeout: 10000,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

instance.interceptors.response.use(async (response) => {
  if (response.status === 202 && !response.config.skip202) {
    const err = new Error('Pending response')
    err.config = response.config
    err.response = response

    // Attach abort controller
    if (isCancellable(err.config)) {
      const controller = new AbortController()
      err.signal = controller.signal

      window.abortControllers[axios.getUri(err.config)] = controller
    }
    throw err
  }
  return response
})

axiosRetry(instance, {
  retries: 1000,
  retryDelay: () => 2000,
  retryCondition(error) {
    return error.message === 'Pending response'
  }
})

export default instance
