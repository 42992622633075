import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'
import '@joint/plus/joint-plus.css'

import './holori.customize.scss'
import './css/main.css'
import './css/mdi.css'
import 'canvg'

import App from './App.vue'
import router from './router'

import { setupInterceptor, setupCurrentUser } from '@/api/interceptor'

import keyboardShortcutDirective from '@/common/keyboard-shortcut-directive'

window.abortControllers = {}

const orugaConfig = Object.assign(bulmaConfig, {
  modal: {
    ...bulmaConfig.modal,
    canCancel: ['escape', 'outside', 'button']
  }
})

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
setupInterceptor()
await setupCurrentUser()
app.use(router)
app.use(VueReCaptcha, {
  siteKey: import.meta.env.VITE_CAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true
  }
})
app.use(Oruga, orugaConfig)
app.directive('keyboard-shortcut', keyboardShortcutDirective)

app.mount('#app')
